
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CountriesTable.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { FaSearch, FaBars } from "react-icons/fa";
import { sortData } from "../sortData"; 
import depimg from "../../images/depimg.png"
import { FaTrash, FaEdit } from "react-icons/fa";
const CountriesTable = () => {
  const [countries, setCountries] = useState([]);
  const [sortOrder, setSortOrder] = useState({
    column: "countryName",
    direction: "asc",
  });
  const [userData, setUserData] = useState();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    axios
      .get("https://papp.deplofy.com:8443/")
      .then((result) => setCountries(result.data))
      
      .catch((err) => console.log(err));
  }, []);
  console.log(countries)
  useEffect(() => {
    fetch("https://papp.deplofy.com:8443/home", {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ token: window.localStorage.getItem("token") }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "userData");
        setUserData({ userData: data.data });
        if (data.data === "Token Expierd") {
          alert("Token Expierd");
          window.localStorage.clear();
          window.location.href = "/";
        }
      });
  }, []);

  const handleDelete = (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      axios
        .delete("https://papp.deplofy.com:8443/delete/" + id)
        .then((resulte) => {
          console.log(resulte);
          window.location.reload();
        })
        .catch((err) => console.log(err));
    }
  };

  const logout = () => {
    window.localStorage.clear();
    window.location.href = "/";
  };
  const toggleMenu = () => {
    setShowMenu(!showMenu); // Toggle the visibility of the side menu drawer
  };

  return (
    <div>
      <div className="all2">
        <Link to='/'>
          <img className="depimg2" src={depimg} alt="Department" />
        </Link>
        {/* Side menu icon */}
        <div className="menu-icon" onClick={toggleMenu}>
          <FaBars />
        </div>
        {/* Side menu drawer */}
        {showMenu && (
          <div className="side-menu">
            <div className="all-sidemenu">
              <div style={{padding:4}}>
            <Link to="/register" className="button-18">
              Add User
            </Link>
            </div>
            <div style={{padding:4}}>
            <Link to="/create" className="button-18">
              Add Country
            </Link>
            </div>
            <div style={{padding:4}}>
            <button onClick={logout} type="submit" className="button-17">
              Logout
            </button>
            </div>
            </div>
          </div>
        )}
      </div>

      <div className="all3">
        <Link to='/search'>
          <button className="button-20">
            Search Country
            <span className="first"></span>
            <span className="second"></span>
            <span className="third"></span>
            <span className="fourth"></span>
          </button>
        </Link>
        <Link to='/price'>
          <button className="button-20"> Calculate
            <span className="first"></span>
            <span className="second"></span>
            <span className="third"></span>
            <span className="fourth"></span>
          </button>
        </Link>
      </div>

      <table className="table table-striped-columns">
        <thead className="text-center">
          <tr>
            <th onClick={() => setSortOrder({ column: "countryName", direction: sortOrder.direction === "asc" ? "desc" : "asc" })} className="country-header">
              Country {sortOrder.direction === 'asc' ? '↑' : '↓'}
            </th>
            <th>Networks</th>
            <th>Provider</th>
            <th>IMSI</th>
            <th>MBR</th>
            <th>GBR</th>
            <th>SMS</th>
            <th>2G</th>
            <th>3G</th>
            <th>4G LTE</th>
            <th>5G</th>
            <th>LTE_M</th>
            <th>NB_IoT</th>
            <th>Notes</th>
            <th>VPMN</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {countries && sortData(countries, sortOrder).map((country) => (
            <tr key={country._id}>
              <td>{country.countryName}</td>
              <td>{country.network}</td>
              <td>{country.provider}</td>
              <td>{country.imsi}</td>
              <td>{country.dataCostPerMB}</td>
              <td>{country.dataCostPerMB * 1024}</td>
              <td>{country.messageRates}</td>
              <td>{country._2G}</td>
              <td>{country._3G}</td>
              <td>{country._4G}</td>
              <td>{country._5G}</td>
              <td>{country.lte_m}</td>
              <td>{country.nb_iot}</td>
              <td>{country.note}</td>
              <td>{country.vpmn}</td>
              <td>
  <div className="btn-container">
    
    <Link to={`/update/${country._id}`}>
      <button type="button" className="icon-edit ">
        <FaEdit /> {/* Use FaEdit icon for edit action */}
      </button>
    </Link>
    <button
      onClick={() => handleDelete(country._id)}
      className="icon-delete "
    >
      <FaTrash /> {/* Use FaTrash icon for delete action */}
    </button>
  </div>
</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CountriesTable;
